import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from '../components/header';
import Button from '../components/common/button';
import PageWrapper from "../components/common/page-wrapper";
import * as ReactFaIcons from "react-icons/fa"
import { graphql } from "gatsby";

const Flex = ({direction="row", children}) => {
    return <div style={{display: "flex", flexDirection: direction}}>
        {children}
    </div>
}

const mapLinks = links => {
let mapped = {};

  for (let i=0; i<links.length; i++) {
    let link = links[i]
    if (!mapped[link.group]) {
      mapped[link.group] = [link]
    } else {
      mapped[link.group] = [...mapped[link.group], link]
    }
  }

  return mapped
}

const LinksPage = ({ data }) => {
  const links = data.allContentfulLink.edges.map(({node}) => ({...node}))
  const mappedLinks = mapLinks(links)
  
  return (
  <Layout>
    <SEO title="Connect With Me" />
    <Header alternate headerTitle="Connect with me" headerText="Here you'll find all you need to connect with me. Feel free to send me a message to say hi or just ask me anything!" />
    <PageWrapper>
        <Flex direction="column">
          {
            Object.keys(mappedLinks).map(section => {
              return <>
                <h4 style={{
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  fontWeight: 'lighter',
                  marginTop: 12
                }}>{section}</h4>
                {
                  mappedLinks[section].map(link => {
           return <Button key={link.id} fullWidth={true} title={link.name}  icon={ReactFaIcons[link.icon]} onClick={() => {
              // got to link
              window.open(link.href)
            }}/>                  })
                }
              </>
            })
          }
        </Flex>
    </PageWrapper>
  </Layout>
)}

export default LinksPage

export const query = graphql`
  query LinkQuery {
    allContentfulLink {
      edges {
        node {
          id
          href
          name
          icon
          group
        }
      }
    }
  }
`