import React from 'react';
import styled from "styled-components";
import COLORS from "../../constants/colors";

const ButtonEl = styled.button`
    margin-bottom: 1rem;
    outline: none;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: ${COLORS.baseColor};
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        background-color: ${COLORS.baseColorDarker}
    }
`

const IconWrapper = styled.span`
    margin-right: 0.5rem;
    height: 20px;
`;


const ButtonText = styled.p`
    margin-bottom: 0;
`

const Button = ({title, icon, onClick}) => {
    const Icon = icon;
    return (
        <ButtonEl onClick={onClick}>
            <IconWrapper>
                <Icon />
            </IconWrapper>
           <ButtonText> {title}</ButtonText>
        </ButtonEl>
    )
}

export default Button